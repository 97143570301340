import { IS_DEV_ENV, masks, querySelector, sha256 } from "../utils/index.utils";
import {  isIOSVersion11OrLess, isMobile, isSmallDevice } from "../utils/responsive.utils";

export default class HomeForm {
  constructor() {
    const {
      height: innerHeight,
    } = window.visualViewport || { height: window.innerHeight };

    this.clientOriginalHeight = innerHeight;
    this.formOpenClass = 'form-is-open';
    this.currentForm = false;
    this.keyboardOpen = false;
    this.clientOriginalHeight = null;
    this.init();
  }

  windowResizeEvent(e) {
    if (!this.isKeyboardOpen() && this.currentForm) {
      this.currentForm.classList.remove(this.formOpenClass);
    }
  };

  isKeyboardOpen() {
    const {
      height: innerHeight,
    } = window.visualViewport || { height: window.innerHeight };

    if (innerHeight < this.clientOriginalHeight) {
      return true;
    } else {
      return false;
    }
  }

  async gaFormTracking(email) {
    const hashedMail = await sha256(email);

    dataLayer.push({
      gaEventCategory: "Contato",
      gaEventAction: "Lead",
      gaEventLabel: hashedMail, //id do lead pode ser e-mail em hash SHA256
      event: "gaSendEvent"
    });
  }

  floatingForm(selector) {
    const form = querySelector(selector);
    const inputs = form.querySelectorAll('input, textarea');
    const { formOpenClass } = this;
    let blockBlur = false;

    let focusEvent = isIOSVersion11OrLess && isSmallDevice ? 'touchstart' : 'focus';

    inputs.forEach(element => {
      element.addEventListener(focusEvent, (event) => {
        if (isIOSVersion11OrLess && isSmallDevice) {
          event.preventDefault();
          event.stopPropagation();
          event.target.focus();
        }

        blockBlur = true;
        this.currentForm = form;
        this.keyboardOpen = true;

        form.classList.add(formOpenClass);
        document.addEventListener("resize", this.windowResizeEvent);
      });

      let bluerEvent = 'blur';

      element.addEventListener(bluerEvent, (event) => {
        if (isIOSVersion11OrLess && isSmallDevice) {
          event.preventDefault();
          event.stopPropagation();
        }
        blockBlur = false;
        setTimeout(() => {
          const isContactForm = this.currentForm.id === 'contato';
          if (blockBlur) {
            return;
          }

          this.currentForm = null;
          this.keyboardOpen = false;
          form.classList.remove(formOpenClass);
          document.removeEventListener("resize", this.windowResizeEvent);
          blockBlur = false;
          if (isContactForm && isMobile) {
            window.scroller.setCurrentSectionIndexWithoutEffect(5);
          }
        }, 1000);
      });
    });
  }

  formSubmitEvents() {
    const forms = document.querySelectorAll('form');
    const showClass = 'show';
    const hideClass = 'hide';

    forms.forEach((form) => {
      form.addEventListener('submit', async (event) => {
        event.preventDefault();
        const formData = new FormData(form);
        const formOjb = Object.fromEntries(formData);
        formOjb.phone = `+${formOjb['phone-ddi']} ${formOjb.phone}`;
        delete formOjb['phone-ddi'];

        let endpoint = form.getAttribute('action');
        let method = 'POST';
        if (IS_DEV_ENV) {
          endpoint = window.location.origin;
        } else {
          endpoint = 'https://vitrio.com.br/api/v1/new-contact';
        }

        fetch(endpoint, {
          method,
          body: JSON.stringify(formOjb),
          mode: "no-cors",
        })
          .then( (response) => {
            if (!response) {
              throw new Error('Something went wrong');
            }

            form.reset();
            const inputsEl = form.querySelector('.inputs');
            inputsEl.classList.add(hideClass);
            const submitSuccessEl = form.querySelector('.submit-success');
            submitSuccessEl.classList.add(showClass);

            setTimeout(() => {
              inputsEl.classList.remove(hideClass);
              submitSuccessEl.classList.remove(showClass);
            }, 10000);

            try {
              this.gaFormTracking(formOjb.email);
            } catch (error) {
              console.error(error);
            }
          })
          .catch((error) => {
            alert('Erro ao enviar o formulário, tente novamente mais tarde ou entre em contato via e-mail.');
            console.error(error);
          });
      });
    });
  }

  onDomContentLoaded() {
    document.addEventListener('DOMContentLoaded', () => {
      this.formSubmitEvents();
    });
  }

  setMobileResizeEvent() {
    [
      '.floating-contact-button',
      '.contact-section',
    ].forEach((selector) => {
      this.floatingForm(selector);
    });
  }

  maskInput() {
    const phoneInputs = document.querySelectorAll('form input[name="phone"]');
    phoneInputs.forEach((input) => {
      input.addEventListener('keyup', (event) => {
        const { value } = event.currentTarget;

        event.currentTarget.value = masks.Tel(value);
      });
    });
  }

  init() {
    this.onDomContentLoaded();
    this.setMobileResizeEvent();
    this.maskInput();
  }
}